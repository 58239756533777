<template>
  <div class="exam_wrap">
    <div class="head_top_Div">
      <img class="head-img" src="@/assets/image/head_bg.png" alt="">
      <div class="head_center">
        <div class="side_top">
          <img src="@/assets/image/hxclass_font2.png" alt="">
          <span>用知识为爱赋能</span>
        </div>
        <span class="text">欢迎来到华夏云课堂考试系统！</span>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.exam_wrap{
  position: relative;
  background: #F8F8F8;
  min-height: 100vh;
  .head_top_Div{
    width: 100%;
    position: relative;
    .head-img{
      width: 100%;
      max-height: 200px;
      min-height: 150px;
    }
    .head_center{
      position: absolute;
      left: 0;
      right:0;
      top: 20%;
      margin: 0 auto;
      width: 85%;
      max-width: 1200px;
      .side_top{
        display: flex;
        justify-content: space-between;
        height: 28px;
        >span{
          font-size: 28px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      .text{
        display: inline-block;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>
